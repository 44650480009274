import React from 'react';
import './About.css';


const About = () => {
  return (
    <div className="">
      hellolkkjnkjnk
    </div>
  );
};

export default About;