import React from 'react';
import './Betting.css';



const About = () => {
  return (
    <div className="">
      hello
    </div>
  );
};

export default About;